import { Languages } from "types/config/i18n";

export type LayerStyle = {
  name: string;
  matches: (data?: any | null) => boolean;
  color: string;
  priority?: number;
};

export type LayerStyleGroup<T = {}> = Array<LayerStyle & T>;

export type LayerStyleGroups<T = {}> = {
  [groupName: string]: LayerStyleGroup<T>;
};

export const PLG_STYLES: LayerStyle[] = [
  {
    name: "noshow",
    priority: 1,
    matches: (data) => {
      return true;
    },
    color: "transparent",
  },
  {
    name: "plg",
    priority: 5,
    matches: (data) => {
      return data?.type === "plg";
    },
    color: "#fff",
  },
  {
    name: "analysisRequested",
    priority: 6,
    matches: (data) => {
      return data?.type === "plg" && data?.analysisRequested === true;
    },
    color: "#cf3f5f",
  },
  {
    name: "statusOk",
    priority: 7,
    matches: (data) => {
      return data?.type === "plg" && data?.plgStatus === "Ok";
    },
    color: "#00faba",
  },
];

export const INDIGENOUS_STYLES: LayerStyle[] = [
  {
    name: "Homologadas",
    priority: 2,
    matches: (data) => {
      return data?.type === "Terra Indígena - Homologada";
    },
    color: "#F4994F",
  },
  {
    name: "Não Homologadas",
    priority: 2,
    matches: (data) => {
      return data?.type === "Terra Indígena - Nao_Homologada";
    },
    color: "#9D7AE5",
  },
  {
    name: "Terra Indígena em Estudo",
    priority: 3,
    matches: (data) => {
      return (
        data?.type === "Terra Indígena - Nao_Homologada" &&
        data?.fase_ti !== "Em Estudo"
      );
    },
    color: "#FCD13A",
  },
  {
    name: "Reserva Indigena",
    priority: 3,
    matches: (data) => {
      return data?.modalidade === "Reserva Indígena";
    },
    color: "#00FFE0",
  },
  {
    name: "Terras Dominiais Indígenas",
    priority: 3,
    matches: (data) => {
      return data?.modalidade === "Dominial Indígena";
    },
    color: "#10B981",
  },
  {
    name: "Áreas sob Interdição",
    priority: 3,
    matches: (data) => {
      return data?.modalidade === "Interditada";
    },
    color: "#FF7AD2",
  },
];

export const PROTECTION_STYLES: LayerStyle[] = [
  {
    name: "Estação Ecológica",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Estação Ecológica";
    },
    color: "#880E4F",
  },
  {
    name: "Reserva Biológica",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Reserva Biológica";
    },
    color: "#F48FB1",
  },
  {
    name: "Parque Nacional",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Parque";
    },
    color: "#1A237E",
  },
  {
    name: "Monumento Natural",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Monumento Natural";
    },
    color: "#795548",
  },
  {
    name: "Refúgio de Vida Silvestre",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Refúgio de Vida Silvestre";
    },
    color: "#673AB7",
  },
];

export const SUSTENTABLE_STYLES: LayerStyle[] = [
  {
    name: "Área de Relevante Interesse Ecológico",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Área de Relevante Interesse Ecológico";
    },
    color: "#9FA8DA",
  },
  {
    name: "Floresta Nacional",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Floresta";
    },
    color: "#BCAAA4",
  },
  {
    name: "Reserva Extrativista",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Reserva Extrativista";
    },
    color: "#87CEAC",
  },
  {
    name: "Reserva de Desenvolvimento Sustentável",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Reserva de Desenvolvimento Sustentável";
    },
    color: "#FFEA00",
  },
  {
    name: "Reserva Particular do Patrimônio Natural",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Reserva Particular do Patrimônio Natural";
    },
    color: "#5F4BB6",
  },
  {
    name: "Área de Proteção Ambiental",
    priority: 1,
    matches: (feature) => {
      return feature?.CATEGORI3 === "Área de Proteção Ambiental";
    },
    color: "#FFCC80",
  },
];

export const EMBARGO_STYLE: LayerStyle[] = [
  {
    name: "Embargo Ambiental (IBAMA)",
    priority: 1,
    matches: (feature) => {
      return feature?.type === "Ibama Embargo";
    },
    color: "#ff4010",
  },
];

export const AMAZON_STYLE: LayerStyle[] = [
  {
    name: "Faixa Tampão",
    priority: 1,
    matches: (feature) => {
      return feature?.type === "legal_amazon";
    },
    color: "#ffc010",
  },
];

export const ALL_STYLES = [
  ...PLG_STYLES,
  ...INDIGENOUS_STYLES,
  ...PROTECTION_STYLES,
  ...SUSTENTABLE_STYLES,
  ...EMBARGO_STYLE,
  ...AMAZON_STYLE,
];
