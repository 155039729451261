import React, { useState, useEffect } from "react";
import { SearchBar, LayerFilter } from "./components/MapOverlay";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { TileLayer, MapContainer, ImageOverlay } from "react-leaflet";
import { Tiles } from "../../mapItens/Tiles";
import { bbox2LatLngBounds } from "../../utils/upn";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import { getPlgBounds } from "../../http-services/data-access";
import Header from "components/header";
import L from "leaflet";
import "leaflet-ruler";
import "esri-leaflet";
import { ImageMapLayer, DynamicMapLayer } from "react-esri-leaflet";
import { AMAZON_STYLE, EMBARGO_STYLE, INDIGENOUS_STYLES, PROTECTION_STYLES, SUSTENTABLE_STYLES } from "../../services/layers";
import { NAVBAR_HEIGHT } from "../../components/header/components/BaseHeader";
import moment from "moment";
import { VectorTileLayer } from "@mapbox/vector-tile";

const BASE_MAP_URL =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

const PLANET_BASE_MAP_URL = (date: string) => `https://tiles{s}.planet.com/basemaps/v1/planet-tiles/planet_medres_visual_${date}_mosaic/gmap/{z}/{x}/{y}.png?api_key=PLAK8a59a3468dc94d928eec68419a14301a`;

const DATES: string[] = [];

const startDate = moment().subtract(45, "days");
const numberOfMonths = 13;

for (let i = 0; i < numberOfMonths; i++) {
  DATES.push(startDate.subtract(i === 0 ? 0 : 1, 'months').format('YYYY-MM'));
}

const SIGMINE_FRONTIER_URL =
  "https://geo.anm.gov.br/arcgis/rest/services/SIGMINE/estrutura_territorial/MapServer/export";

const MVT_SERVER_URL =
  process.env.REACT_APP_MVT_SERVER_URL ?? "http://localhost:8000/{type}/{z}/{x}/{y}.pbf";


const PlanetTileLayer = (props: { active: boolean, date?: string | null }) => {
  const { date, active } = props;

  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [date]);

  if (!date) return <></>;

  return <TileLayer
    key={key}
    opacity={active ? 1 : 0}
    zIndex={1}
    attribution="&copy; Planet.com"
    url={PLANET_BASE_MAP_URL(date)}
    subdomains={["0", "1", "2", "3"]}
  />
};

const Map: React.FC<any> = () => {
  const [filters, setFilters] = useState<string[]>([]);
  const [plgFilter, setPlgFilter] = useState<string[]>(["plg"]);
  const [map, setMap] = useState<L.Map>();
  const [mapCoordinates, setMapCoordinates] = useState({ lat: 0, lng: 0 });
  const [upn, setUpn] = useState<string>();
  const [bbox, setBbox] = useState<string>();
  const [screenshooter, setScreenShooter] = useState<any>();
  const [showFrontier, setShowFrontier] = useState<boolean>(false);
  const [showSatellite, setShowSatellite] = useState<boolean>(false);
  const [showAmazon, setShowAmazon] = useState<boolean>(false);
  const [showEmbargo, setShowEmbargo] = useState<boolean>(false);
  const [showStates, setShowStates] = useState<boolean>(false);
  const [showPlgs, setShowPlgs] = useState<boolean>(true);
  const [date, setDate] = useState<string | null>(DATES[0]);


  const onFeatureChange = (upn: string) => {
    setUpn(upn);
  };

  const onPlgFilterChange = (value: string) => {
    setPlgFilter([value, "plg"]);
  };

  const onSearch = async (upn: string) => {
    const { bbox } = await getPlgBounds(upn);

    setUpn(upn.toString());

    setBbox(bbox);
  };

  useEffect(() => {
    if (map) {
      const ss = new SimpleMapScreenshoter({ hidden: true });
      ss.addTo(map);
      setScreenShooter(ss);
      map.zoomControl.setPosition("bottomleft");
      map.addEventListener("mousemove", (e) => {
        setMapCoordinates({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        });
      });
      (L.control as any).ruler({ position: "bottomleft" }).addTo(map);
    }
  }, [map]);

  useEffect(() => {
    if (map && bbox) {
      const bounds = bbox2LatLngBounds(bbox);

      if (bounds) map.flyToBounds(bounds, { maxZoom: 12, animate: false });
    }
  }, [map, bbox]);

  const onLayerFilterChange = (f: string[]) => {
    const v = Array.from(new Set([plgFilter, ...f])).filter(
      (value) => value !== undefined,
    ) as string[];
    setFilters(v);
  };


  const stack = {
    position: "absolute",
    top: "6rem",
    msOverflowStyle: "none",
    scrollbarColor: "transparent transparent",
    width: "375px",
    borderRadius: 2,
    zIndex: 1000,
    maxHeight: "70vh",
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Header />
      <Stack
        sx={{
          ...stack,
          left: "1rem",
        }}
      >
        <SearchBar
          upn={upn}
          screenshooter={screenshooter}
          onSearch={(upn) => onSearch(upn)}
          onFilterChange={(filterBy) => onPlgFilterChange(filterBy)}
        />
      </Stack>
      <Stack
        sx={{
          ...stack,
          right: "1rem",
        }}
      >
        <LayerFilter
          onFiltersChange={(f) => onLayerFilterChange(f)}
          onFrontierToggle={setShowFrontier}
          onSatelliteToggle={setShowSatellite}
          onLegalAmazonToggle={setShowAmazon}
          onEmbargoToggle={setShowEmbargo}
          onStateToggle={setShowStates}
          onPlgToggle={setShowPlgs}
          groups={{
            "Faixa Tampão (Portaria 60/2015)": AMAZON_STYLE,
            "Terras Indígenas": INDIGENOUS_STYLES,
            "Unidades de Proteção Integral": PROTECTION_STYLES,
            "Unidades de Uso Sustentável": SUSTENTABLE_STYLES,
            "Ambiental": EMBARGO_STYLE,
          }}
        />
      </Stack>

      <MapContainer
        style={{
          position: "absolute",
          height: `calc(100% - ${NAVBAR_HEIGHT})`,
          width: "100%",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        center={[-14.2400732, -53.1805017]}
        zoom={6}
        minZoom={5}
        maxZoom={17}
        scrollWheelZoom={true}
        zoomControl={true}
        ref={(map) => map && setMap(map)}
      >
        <TileLayer
          opacity={1}
          zIndex={1}
          attribution="&copy; ESRI &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          url={BASE_MAP_URL}
          subdomains={["0", "1", "2", "3"]}
        />
        <PlanetTileLayer date={date} active={showSatellite} />
        {/* <VectorTileLayer date={date} active={showAmazon} /> */}
        <Tiles
          key="plg"
          url={MVT_SERVER_URL}
          opacity={0.2}
          show={showPlgs}
          zIndex={5}
          featureId={upn}
          filters={plgFilter}
          onFeatureChange={onFeatureChange}
        />
        <Tiles
          key="layers"
          url={MVT_SERVER_URL}
          opacity={0.2}
          featureId={upn}
          filters={[...filters].filter(Boolean) as string[]}
          onFeatureChange={onFeatureChange}
        />
        {/* {showFrontier ? (
          <ImageMapLayer url="https://geo.anm.gov.br/arcgis/rest/services/SIGMINE/estrutura_territorial/MapServer/export?transparent=true&layers=show:3"></ImageMapLayer>
        ) : (
          <></>
        )} */}
        {showStates ? (
          <ImageMapLayer url="https://geo.anm.gov.br/arcgis/rest/services/SIGMINE/estrutura_territorial/MapServer/export?transparent=true&layers=show:-1,-1,-1,6,1"></ImageMapLayer>
        ) : (
          <></>
        )}
      </MapContainer>
      <Box
        sx={{
          position: "absolute",
          bottom: 25,
          right: 16,
          width: 375,
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            display: showSatellite ? "block" : "none",
            mb: 2,
          }}
        >
          <Select
            sx={{
              width: "100%",
              background: "white",
            }}
            value={date}
            label="Data da Imagem"
            onChange={(e) => {
              setDate(null);
              setDate(e.target.value);
            }}
          >
            {DATES.map(date => <MenuItem value={date}>{date}</MenuItem>)}
          </Select>
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            columnGap: "20px",
            fontSize: "10px",
            opacity: "0.75",
          }}
        >
          <span>lat: {mapCoordinates.lat}</span>
          <span>lng: {mapCoordinates.lng} </span>

        </Box>
      </Box>
    </Box>
  );
};

export default Map;
